<div class="cart-preview-card animated fadeInRight">

  @if (!isJustAddedProductNotification()) {
  <header class="d-flex justify-content-end">
    <span class="cart-preview-card__tag badge " [ngClass]="{'bg-success': isInOrder(), 'bg-warning': !isInOrder()}">
      {{isInOrder() ? 'In your order' : 'Not yet saved'}}
    </span>
  </header>
  }

  <section class="d-flex justify-content-between align-items-center gap-3">

    <div class="cart-preview-card__img">
      <a [routerLink]="['/shop/'+(product()?.productUrl || '')]">
        <img [src]="product()?.img || 'assets/images/product-placeholder.webp'" alt="image"
          (error)="handleImageError($event)" />
      </a>
    </div>

    <div class="d-flex flex-column justify-content-center flex-grow-1 width-100 gap-2">

      <!-- Name -->
      <a class="title" [routerLink]="['/shop/'+(product()?.productUrl || '')]">
        {{ product().name }}
      </a>

      <div class="d-flex justify-content-between align-items-center">

        <div class="cart-preview-card__content">

          <!-- Display -->
          <span class="subtitle">
            @if (product()?.variant?.attribute?.name) {
            {{product()?.variant?.attribute?.name}}: {{product()?.variant?.attribute?.value?.name}}
            } @else if (product()?.package) {
            Package: {{ product()?.package?.name }}
            }

            @if (product()?.subscription) {
            {{((product()?.package?.name || product()?.variant?.attribute?.name) ? ' | ' : '') +
            product().subscription?.frequency?.name}}
            }
          </span>

          <!-- Price -->
          @if (!product().bundle?.items?.length && product().bundle?.priceRange) {
          <span class="price opacity-0">
            {{ product().bundle.priceRange }}
          </span>
          } @else {
          <span class="price">
            {{ (product()?.bundle?.items?.length ? product()?.totalPrice : product().price) | currency}}
          </span>
          }

        </div>

        @if (!isJustAddedProductNotification()) {
        <div class="d-flex flex-column gap-2">
          <div class="input-plus-minus">
            @if(allowModification()) {

            <button type="button" class="btn-plusminus" (click)="handleMinus()" ngbTooltip="Remove Quantity"
              placement="bottom" tooltipClass="tooltip-sm" [disabled]="newQuantity() <= 1">
              -
            </button>

            <mat-form-field class="d-flex" subscriptSizing="dynamic">
              <input type="text" matInput appNumberRestriction [minNumber]="1" [maxNumber]="100"
                (keyup)="onKeyUp($event)" (keyup.enter)="updateQuantity()" (blur)="updateQuantity()"
                [disabled]="!allowModification()" [value]="newQuantity()">
            </mat-form-field>

            <button type="button" class="btn-plusminus" (click)="handlePlus()" ngbTooltip="Add Quantity"
              placement="bottom" tooltipClass="tooltip-sm">
              +
            </button>

            } @else {
            <mat-form-field class="d-flex">
              <input type="text" matInput [value]="newQuantity()" readonly>
            </mat-form-field>
            }
          </div>

          @if(allowModification()) {
          <span class="cart-preview-card__delete link-color" (click)="removeCartProduct()">
            Delete
          </span>
          }

        </div>
        }

      </div>

    </div>

  </section>

  @if (product()?.bundle && product()?.bundle?.items?.length && !isJustAddedProductNotification()) {

  <a class="text-start cart-preview-card__delete link-color" data-bs-toggle="collapse"
    [attr.data-bs-target]="'#toggle_' + product().id" aria-expanded="false" aria-controls="collapseExample">
    What's inside
  </a>

  <div class="collapse bg-primary bg-opacity-10 pt-2 rounded-4" [id]="'toggle_'+product().id">
    @for (item of product().bundle.items; track $index) {
    <div class="row d-flex justify-content-between p-3 py-1 animated fadeIn">
      <div class="col-8">
        <h6 class="h8-semibold">{{ item.name }} {{item.packageName ? (' | ' +
          item.packageName) : (item.display ? (' | ' + item.display) : '')}}</h6>
        @if (item?.presentation) {
        <small class="h7-semibold">
          {{ item.presentation.quantity }}
          {{ item.presentation.type }}
        </small>
        }
      </div>
      <div class="col-4">
        <h6 class="h8-semibold text-end">{{item.quantity}}</h6>
      </div>
    </div>
    }
  </div>

  @if(!product().bundle.isLocked) {
  <button type="button" class="btn btn-outline-secondary h6-bold mt-2" (click)="goToCustomizeBox()">
    Customize Box
  </button>
  }

  }

</div>