import { inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { RequestHandlerParams } from './types/api-service.types';
import { MarketingReferral } from './types/common.types';

@Injectable({
    providedIn: 'root'
})
export class PromotionsService {

    #apiService = inject(ApiService);


    getCouponInfo(promotionInfo: MarketingReferral): Observable<any> {
        const payload: RequestHandlerParams = {
            endpoint: `/promotions`,
            method: 'POST',
            apiV3: true,
            body: promotionInfo
        };

        return this.#apiService.handleRequest(payload);
    }
}